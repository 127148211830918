<template>
<div>
  <Header/>

  <b-container
    style="padding-top:70px; max-width:95%; font-size:14px;"
  >
    <b-overlay
      :show="showLoader"
      rounded="sm"
    >
      <b-row >
        <b-col>
          <h3
            class="mb-0"
          >
            内観写真登録
          </h3>
        </b-col>
      </b-row>

      <b-tabs
        v-model="tabIndex"
        nav-wrapper-class="tab-info"
        lazy
      >
        <b-tab
          @click="pageTop"
          v-for="(locationData,locationIndex) in locationDatas"
          :key="locationIndex"
          :title="locationData.vcEstateName"
        >
          <b-row>
            <b-col
              md="2"
            >
              <SideMenu
                class="sidebar-area"
                :estateID="locationData.cEstateID"
                :locationName="locationData.vcLocationName"
              />
            </b-col>
            <b-col
              md="10"
            >
              <b-row
                class="property_info "
              >
                <b-col
                  md="3"

                >
                  <h6
                    class="mb-0"
                  >
                    {{ locationData.vcEstateName }}
                  </h6>
                  <p
                    class="mb-0"
                  >
                    {{ locationData.vcAddress }}
                  </p>
                  <div
                    style="white-space: pre-wrap;"
                  >
                  備考:
                    {{ locationData.txInterior }}
                  </div >

                </b-col>
                <b-col
                  class="ms-2"
                >
                  <b-row
                    style="overflow-x: scroll; max-height:150px;"

                  >
                    <b-col
                      v-for="(materialData,materialDataIndex) in locationData.material"
                      :key="materialDataIndex"
                    >
                      <b-col
                        v-if="materialData.vcStorage"
                      >
                        <b-img
                          :class="{'img_link' : materialData.vcStorage != noImage}"
                          :src="materialData.vcStorage"
                          @click="showEstateImage(
                              materialData.vcStorage,
                              locationData.vcLocationName,
                              locationData.vcEstateName,

                            )"
                          style="max-width:200px; max-height:130px;"
                        />
                      </b-col>
                      <b-col
                        v-else-if="!materialData.vcMaterialID.includes('zip')"
                      >
                        <b-link
                          :to="{name:'PDFPage', params : {
                            materialID: materialData.vcMaterialID,
                            materialName: materialData.materialName }}"
                          target="_blank"
                          style="text-decoration:none; "
                        >
                          {{ materialData.materialName }}
                          <pdf
                            style="max-width:200px;height:100px;"
                            :src="materialData.materialURL"
                          ></pdf>
                        </b-link>
                      </b-col>
                      <b-col
                        v-else-if="materialData.vcMaterialID.includes('zip')"
                      >
                        <b-link
                          :href="materialData.materialURL"
                          download
                        >
                          {{ materialData.materialName }}(zip)
                        </b-link>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
                <b-row
                >

                  <b-col
                    class="text-center"
                  >
                    <b-button
                      v-if="locationData.photo.length == 0"
                      @click="postPropertyPhotoModal(
                        locationData.cLocationID,
                        locationData.cEstateID,
                        locationData.vcEstateName,
                        estateDatas[locationIndex][locationData.cEstateID],
                      )"
                      variant="primary"
                      style="width: 50%;"
                      size="sm"
                    >
                      写真を登録する
                    </b-button>
                    <b-button
                      v-else
                      @click="postPropertyPhotoModal(
                        locationData.cLocationID,
                        locationData.cEstateID,
                        locationData.vcEstateName,
                        estateDatas[locationIndex][locationData.cEstateID]
                      )"
                      variant="success"
                      style="width: 50%;"
                      size="sm"
                    >
                      写真を更新する
                    </b-button>
                  </b-col>
                </b-row>
                <hr class="mb-1">
              </b-row>
              <b-row>
                <label
                  class="col-lg-2 col-md-3 col-form-label w-auto"
                  for="matterportURL"
                >
                  マターポートURL:
                </label>
                <b-col
                  md="4"
                  lg="auto"
                  sm="5"
                >
                  <b-form-input
                    trim
                    size="sm"
                    id="matterportURL"
                    type="text"
                    v-model="estateDatas[locationIndex][locationData.cEstateID].matterportURL"
                  />
                </b-col>
                <label
                  class="col-lg-2 col-md-3 col-form-label w-auto"
                  for="deadlineMatterport"
                >
                  URL有効期限:
                </label>
                <b-col
                  md="4"
                  lg="auto"
                  sm="5"
                >
                  <b-form-input
                    size="sm"
                    id="deadlineMatterport"
                    type="date"
                    v-model="estateDatas[locationIndex][locationData.cEstateID].deadlineMatterport"
                  />
                </b-col>
              </b-row>

              <b-row
                class=" mb-3"
                v-for="(insidePhotoForm , index) in photoForms[locationIndex][locationData.cEstateID]"
                :key="index"
              >
                <!-- 写真登録フォーム -->
                <b-col
                  v-if="!insidePhotoForm[0].photoFormSortflag"
                  md="11"
                >
                  <b-overlay
                    variant="secondary"
                    opacity="0.4"
                    :show="!insidePhotoForm[0].showForm"
                  >
                    <template #overlay>
                      <div>
                      </div>
                    </template>
                    <div
                      :id="'a' + insidePhotoForm[0].category"
                    >
                      <b-button
                        @click="sortPhoto(
                          insidePhotoForm[0].estateID,
                          insidePhotoForm[0].insidePlace,index
                        )"
                        variant="outline-success"

                      >
                        写真並び替え
                      </b-button>
                    </div>
                    <carousel
                      :per-page="mobile ? 1 : 3"
                      :navigationEnabled="mobile ? false: true"
                      :mouseDrag="false"
                      :touchDrag="true"
                      :value="carouselPage"
                      ref="carousel"
                      navigation-prev-label="〈"
                      navigation-next-label="〉"
                    >

                      <slide
                        class="p-2 "
                        v-for="(photoForm,formIndex) in insidePhotoForm"
                        :key="photoForm.insidePlace + formIndex"
                      >
                        <b-row>
                          <b-col >
                            {{ photoForm.insidePlace + (formIndex + 1)}}
                          </b-col>
                          <b-col
                            md="9"

                            v-if="photoForm.insidePlace == 'その他'"
                          >
                            <b-row>
                              <b-col
                                md="3"
                              >
                                <label
                                  :for="photoForm.insidePlace + formIndex"
                                  class=" col-form-label"
                                >
                                  名称:
                                </label>
                              </b-col>
                              <b-col>
                                <b-form-input
                                  :id="photoForm.insidePlace + formIndex"
                                  class="col col-md-3"
                                  v-model="photoForm.othersName"
                                  type="text"
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <div
                          class="image-input mx-auto "
                        >
                          <div
                            class="image-input__field"
                            :class="{'over': photoForm.isDragOver}"
                            @dragover.prevent="onDrag('over',photoForm.formID,photoForm.estateID)"
                            @dragleave="onDrag('leave',photoForm.formID,photoForm.estateID)"
                            @drop.stop="onDrop"
                            :name="[photoForm.formID,formIndex]"
                            :id="photoForm.estateID"
                          >
                            <input

                              v-if="!photoForm.image.src && photoForm.existingImage == ''"
                              :name="[photoForm.formID,formIndex]"
                              :id="photoForm.estateID"
                              type="file"
                              @change="onChange"
                            >
                            <div
                              v-if="photoForm.image.src && photoForm.existingImage == ''"
                            >
                              <div
                                style="text-align:right;"
                              >
                                <button
                                  type="button"
                                  class="btn-close "
                                  aria-label="Close"
                                  @click="deleteImage(
                                    photoForm.formID,
                                    photoForm.estateID
                                  )"
                                ></button>
                              </div>
                              <b-img
                                :src="photoForm.image.src"
                                class="img-thumbnail d-flex mx-auto"
                                style="max-height: 280px;"
                              />
                            </div>
                            <div
                              v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                            >
                              <div
                                style="text-align:right;"
                              >
                                <button
                                  type="button"
                                  class="btn-close "
                                  aria-label="Close"
                                  @click="deleteImage(
                                    photoForm.formID,
                                    photoForm.estateID
                                    )"
                                ></button>
                              </div>
                              <b-img
                                :src="photoForm.existingImage"
                                class="img-thumbnail d-flex mx-auto"
                                style="max-height: 280px;"
                              />
                            </div>

                            <p
                              v-else
                            >
                              画像をドラッグ＆ドロップ
                              <br>またはクリックでファイル選択
                            </p>
                          </div>
                        </div>
                      </slide>
                    </carousel>
                  </b-overlay>
                </b-col>

                <b-col
                  v-if="create && !insidePhotoForm[0].photoFormSortflag"
                  class="d-flex align-items-center justify-content-center "
                  md="1"
                >
                  <span
                    v-for="(insideData,insideDataIndex) in estateDatas[locationIndex][locationData.cEstateID].inside[locationData.cEstateID] "
                    :key="insideDataIndex"
                  >
                    <span
                      v-if="insideData[locationData.cEstateID + insidePhotoForm[0].insidePlace]"
                    >
                      <b-img
                        :hidden="!insideData[locationData.cEstateID + insidePhotoForm[0].insidePlace].requiredFormFlag"
                        @click="makeAddInsidePhotoForms(
                          insidePhotoForm[0].estateID,
                          insidePhotoForm[0].insidePlace,
                          insidePhotoForm[0].interiorID,
                          insidePhotoForm[0].estateName
                        )"
                        src='/static/images/addbtn.png'
                        class="img_link add_btn"

                      />
                    </span>
                  </span>
                </b-col>

                <!-- 写真並べ替え時表示 -->
                  <b-col
                    md="2"
                    v-if="insidePhotoForm[0].photoFormSortflag"
                  >
                    <b-button
                      @click="changeSort(insidePhotoForm[0].estateID,insidePhotoForm[0].insidePlace)"
                      variant="outline-success"
                    >
                      並び替える
                    </b-button>
                  </b-col>
                  <b-row
                    v-if="insidePhotoForm[0].photoFormSortflag"
                    style="overflow-x: scroll;"
                  >
                    <draggable
                      class="d-flex flex-row ddSort "
                      :animation="200"
                      v-model="photoForms[locationIndex][locationData.cEstateID][count]"
                    >
                      <b-col
                        v-for="(photoForm,formIndex) in insidePhotoForm"
                        :key="photoForm.insidePlace + formIndex"
                        style="margin-right: 15px;"
                        md="4"
                      >
                        <b-row>
                          <b-col>
                            {{ photoForm.insidePlace + (formIndex + 1)}}
                          </b-col>
                        </b-row>
                        <div
                          class="image-input mx-auto col-md-6"
                        >
                          <div
                            class="image-input__field"
                          >
                            <div
                              v-if="photoForm.image.src && photoForm.existingImage == ''"
                            >
                              <b-img
                                :src="photoForm.image.src"
                                class="img-thumbnail d-flex mx-auto"
                                style="max-height: 280px;"
                              />
                            </div>
                            <div
                              v-else-if="!photoForm.image.src && photoForm.existingImage != ''"
                            >
                              <b-img
                                :src="photoForm.existingImage"
                                class="img-thumbnail d-flex mx-auto"
                                style="max-height: 280px;"
                              />
                            </div>
                            <p v-else>画像をドラッグ＆ドロップ
                              <br>またはクリックでファイル選択
                            </p>
                          </div>
                        </div>
                      </b-col>
                    </draggable>
                  </b-row>
                </b-row>
              <hr>
            </b-col>
          </b-row>
        </b-tab>

      </b-tabs>
    </b-overlay>
  </b-container>
  <ShowPDFModal
    :pdfData="pdfData"
  />
  <ShowEstatePhotoModal
    :estateName="photoEstateName"
    :locationName="photoLocationName"
    :estateImage="photoEstateImage"
  />
</div>
</template>

<script>
import Header from '../../components/Header.vue'
import ShowPDFModal from '../../components/ShowPDFModal.vue'
import ShowEstatePhotoModal from '../../components/ShowEstatePhotoModal.vue'
import SideMenu from '../../components/InsidePropertySideMenu.vue'
import { Apimixin } from '../../mixins/api'
import { UploadImage } from '../../mixins/propertyuploadimage'
import draggable from 'vuedraggable'
import moment from 'moment'

export default {
  title:'内観写真登録',
  mixins:[Apimixin,UploadImage],
  components: {
    Header,
    draggable,
    ShowPDFModal,
    SideMenu,
    ShowEstatePhotoModal
  },
  data() {
    return {
      pdfData:'',
      userID:'',
      shootingID:'',
      process:[],
      insidePlaces:[
        {id:1,text:'リビング',value:'リビング',category:'リビング・キッチン',show:true},
        {id:2,text:'ダイニング',value:'ダイニング',category:'リビング・キッチン',show:false},
        {id:7,text:'キッチン',value:'キッチン',category:'リビング・キッチン',show:true},
        {id:8,text:'パントリー',value:'パントリー',category:'リビング・キッチン',show:false},
        {id:3,text:'居室(洋室)',value:'居室(洋室)',category:'居室',show:true},
        {id:4,text:'居室(和室)',value:'居室(和室)',category:'居室',show:false},
        {id:5,text:'サービスルーム(S)',value:'サービスルーム(S)',category:'居室',show:false},
        {id:6,text:'納戸(N)',value:'納戸(N)',category:'居室',show:false},
        {id:9,text:'浴室',value:'浴室',category:'水回り',show:true},
        {id:10,text:'トイレ',value:'トイレ',category:'水回り',show:true},
        {id:11,text:'洗面所',value:'洗面所',category:'水回り',show:true},
        {id:12,text:'玄関',value:'玄関',category:'玄関',show:true},
        {id:13,text:'廊下',value:'廊下',category:'玄関',show:false},
        {id:14,text:'ロフト',value:'ロフト',category:'収納',show:true},
        {id:15,text:'収納(ウォークインクローゼット)',value:'収納(ウォークインクローゼット)',category:'収納',show:true},
        {id:16,text:'収納(ウォークスルークローゼット)',value:'収納(ウォークスルークローゼット)',category:'収納',show:false},
        {id:17,text:'収納(シューズクローク)',value:'収納(シューズクローク)',category:'収納',show:true},
        {id:18,text:'収納(床下)',value:'収納(床下)',category:'収納',show:true},
        {id:19,text:'収納(押入れ)',value:'収納(押入れ)',category:'収納',show:false},
        {id:20,text:'収納(その他)',value:'収納(その他)',category:'収納',show:false},
        {id:21,text:'眺望',value:'眺望',category:'外回り',show:true},
        {id:22,text:'バルコニー',value:'バルコニー',category:'外回り',show:true},
        {id:23,text:'庭',value:'庭',category:'外回り',show:true},
        {id:24,text:'設備',value:'設備',category:'外回り',show:false},
        {id:25,text:'外観写真',value:'外観写真',category:'外回り',show:true},
        {id:26,text:'駐車場',value:'駐車場',category:'外回り',show:true},
        {id:27,text:'パノラマ',value:'パノラマ',category:'その他',show:true},
        {id:28,text:'その他',value:'その他',category:'その他',show:true},
      ],
      drag:false,
      notExifData:false,
      photoForms:[],
      estateDatas:[],
      constructionPhotoForms:[],
      copyConstructionChecks:[],
      copyPhotoFormChecks:[],
      estateIDs:[],
      message:{
        notDate:''
      },
      introspectionDatas:[],
      create:false,
      tabInfo:'.tab-info',
      count:'',
      carouselPage:0,
      exteriorPhotoDatas:[],
      locationDatas:[],
      shootingType:'',
      photoLocationName:'',
      photoEstateName:'',
      photoEstateImage:'',
      tabIndex:0,
      estateID:'',
      locationType:''
    }
  },
  created() {
    this.showLoader = true
    this.shootingType = this.$route.params.shootingType
    this.shootingID = this.$route.params.shootingID
    this.userID = sessionStorage.getItem('userId')
  },

  mounted() {
    this.estateID = this.$route.params.estateID
    //物件詳細情報取得
    this.getEstateDetail(
      this.$route.params.locationID,
      this.$route.params.shootingType,
      this.$route.params.shootingDate,
      this.$route.params.userID,
    )
    setTimeout(this.flag, 1000);
    //該当の物件タブにアクティブ
    this.getTabIndex()
  },

  methods:{
    //各種フォーム作成
    createPhotoForms() {
      for(let locationData of this.locationDatas) {
        this.photoForms.push({[locationData.cEstateID]:[]})
        this.estateIDs.push(locationData.cEstateID)
        this.introspectionDatas.push({[locationData.cEstateID]:[]})
      }
    },
    //内観情報の取得
    createintrospectionDatas() {
      for(let introspectionData of this.introspectionDatas) {
        this.estateIDs.filter(
          (estateID) => {
            if(estateID == Object.keys(introspectionData)[0]) {
              for(let insidePlace of this.insidePlaces) {
                introspectionData[estateID].push({[estateID + insidePlace.text]:
                  {
                    photoFormSortflag:false,
                    requiredFormFlag:false,
                  }
                })
              }
            }
          }
        )
      }
    },

    //物件情報フォーム
    makeEstateData(estateID,estateName,inside,matterportURL,deadlineMatterport) {
      for(let i = 0;i < inside.length;i++) {
        if(Object.keys(inside[i]) == estateID) {
          this.estateDatas.push({[estateID]:
            {
              estateID:estateID,
              estateName:estateName,
              photoFormSortflag:false,
              registProperty:false,
              constructionflag:false,
              nextShoot:'',
              comment:'',
              inside:inside[i],
              matterportURL:matterportURL,
              deadlineMatterport:deadlineMatterport
            }
          })
        }
      }
    },
    //写真投稿フォーム作成
    makePhotoForms(estateID,estateName) {
      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let i = 0;i < this.insidePlaces.length;i++) {
            photoForm[estateID].push([
              {
                estateName:estateName,
                estateID:estateID,
                formID:this.insidePlaces[i].text + 0,
                category:estateID + this.insidePlaces[i].category,
                insidePlace:this.insidePlaces[i].text,
                othersName:'',
                shootDate: '',
                nextShoot: '',
                image: '',
                orderNo:'',
                existingImage:'',
                uploadFile:[],
                resizeUploadFile:[],
                isDragOver:false,
                uploadFlag:false,
                photoFormSortflag:false,
                interiorID:this.insidePlaces[i].id,
                photoID:'',
                processNumber:'',
                showForm:this.insidePlaces[i].show,
                message:{
                  notDate:''
                }
              },
              {
                estateName:estateName,
                estateID:estateID,
                formID:this.insidePlaces[i].text + 1,
                insidePlace:this.insidePlaces[i].text,
                othersName:'',
                shootDate: '',
                nextShoot: '',
                image: '',
                orderNo:'',
                existingImage:'',
                uploadFile:[],
                resizeUploadFile:[],
                isDragOver:false,
                uploadFlag:false,
                photoFormSortflag:false,
                interiorID:this.insidePlaces[i].id,
                photoID:'',
                processNumber:'',
                showForm:this.insidePlaces[i].show,
                message:{
                  notDate:''
                }
              },
              {
                estateName:estateName,
                estateID:estateID,
                formID:this.insidePlaces[i].text + 2,
                insidePlace:this.insidePlaces[i].text,
                othersName:'',
                shootDate: '',
                nextShoot: '',
                image: '',
                orderNo:'',
                existingImage:'',
                uploadFile:[],
                resizeUploadFile:[],
                isDragOver:false,
                uploadFlag:false,
                photoFormSortflag:false,
                interiorID:this.insidePlaces[i].id,
                photoID:'',
                processNumber:'',
                showForm:this.insidePlaces[i].show,
                message:{
                  notDate:''
                }
              }
            ])
          }
        }
      }
      this.getExistingPhotoData()
    },
    //既存の写真があるかチェック
    getExistingPhotoData() {
      let orderID = ''
      for(let locationData of this.locationDatas) {
        if(locationData.photo.length != 0) {
          for(let existingPhotoData of locationData.photo) {
            orderID = (existingPhotoData.inOrder - 1)
            for(let photoForm of this.photoForms) {
              if(Object.keys(photoForm)[0] == existingPhotoData.cEstateID) {
                for(let i = 0;i < photoForm[existingPhotoData.cEstateID].length;i++) {
                  if(photoForm[existingPhotoData.cEstateID][i][0].interiorID == existingPhotoData.inInteriorID) {
                    if(typeof photoForm[existingPhotoData.cEstateID][i][orderID] !== "undefined") {
                      photoForm[existingPhotoData.cEstateID][i][orderID].existingImage = this.resizeImgURL + existingPhotoData.vcStorage
                      photoForm[existingPhotoData.cEstateID][i][orderID].photoID = existingPhotoData.inPhotoID
                      photoForm[existingPhotoData.cEstateID][i][orderID].orderNo = existingPhotoData.inOrder
                      photoForm[existingPhotoData.cEstateID][i][orderID].uploadFlag = true
                      photoForm[existingPhotoData.cEstateID][i][orderID].shootDate = existingPhotoData.dtPhotoDate
                      photoForm[existingPhotoData.cEstateID][i][orderID].othersName = existingPhotoData.vcOthers
                      this.checkForm(
                        existingPhotoData.cEstateID,
                        photoForm[existingPhotoData.cEstateID][i][orderID].formID
                      )

                    } else {
                      photoForm[existingPhotoData.cEstateID][i].push(
                        {
                          estateName:photoForm[existingPhotoData.cEstateID][i][0].estateName,
                          estateID:photoForm[existingPhotoData.cEstateID][i][0].estateID,
                          formID:photoForm[existingPhotoData.cEstateID][i][0].insidePlace  ,
                          shootDate: '',
                          nextShoot: '',
                          comment: '',
                          image: '',
                          orderNo:'',
                          existingImage:'',
                          uploadFile:[],
                          resizeUploadFile:[],
                          isDragOver:false,
                          insidePlace:photoForm[existingPhotoData.cEstateID][i][0].insidePlace,
                          othersTitle:'',
                          interiorID:photoForm[existingPhotoData.cEstateID][i][0].interiorID,
                          photoID:'',
                          photoFormSortflag:false,
                          uploadFlag:false,
                          processNumber:'',
                          message:{
                            notDate:''
                          }
                        }
                      )
                      this.getExistingPhotoData()
                      return
                    }
                  }
                }
              }
            }
          }
        } else {
          return
        }
      }
    },
    pageTop() {
      this.$scrollTo('#app');
    },
    //PDF表示モーダルオープン
    async ShowPDFModal(pdf) {
      this.pdfData = ''
      this.$bvModal.show('ShowPDFModal')
      this.pdfData = pdf
    },

    //画像拡大表示
    showEstateImage(estateImage,locationName,estateName) {
      if(estateImage !== this.noImage) {
        estateImage =estateImage.replace('/resizephoto/','/photo/')
        this.photoLocationName = locationName
        this.photoEstateName = estateName
        this.photoEstateImage = estateImage
        this.$bvModal.show('ShowEstatePhotoModal')
      }
    },

    //並べ替え開始
    sortPhoto(estateID,insidePlace,index) {
      this.count = ''
      this.count = index

      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let t = 0;t <  photoForm[estateID].length;t++) {
            for(let p = 0;p < photoForm[estateID][t].length;p++) {
              if(photoForm[estateID][t][0].insidePlace == insidePlace) {
                photoForm[estateID][t][p].photoFormSortflag = true
              }
            }
          }
        }
      }
    },
    //並べ替え終了
    changeSort(estateID,insidePlace) {
      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let t = 0;t <  photoForm[estateID].length;t++) {
            for(let p = 0;p < photoForm[estateID][t].length;p++) {
              if(photoForm[estateID][t][0].insidePlace === insidePlace) {
                photoForm[estateID][t][p].photoFormSortflag = false
                photoForm[estateID][t][p].orderNo = p + 1
                photoForm[estateID][t][p].processNumber = ''
              }
            }
          }
        }
      }
    },

    //物件写真投稿フォームを追加
    makeAddInsidePhotoForms(estateID,insidePlace,interiorID,estateName) {
      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let t = 0; t < photoForm[estateID].length;t++) {
              if(photoForm[estateID][t][0].interiorID == interiorID) {
                photoForm[estateID][t].push({
                  estateName:estateName,
                  estateID:estateID,
                  formID:insidePlace + photoForm[estateID][t].length ,
                  shootDate: '',
                  nextShoot: '',
                  comment: '',
                  image: '',
                  orderNo:'',
                  existingImage:'',
                  uploadFile:[],
                  resizeUploadFile:[],
                  isDragOver:false,
                  insidePlace:insidePlace,
                  othersTitle:'',
                  interiorID:interiorID,
                  photoID:'',
                  photoFormSortflag:false,
                  uploadFlag:false,
                  processNumber:'',
                  message:{
                    notDate:''
                  }
                })
                this.$refs.carousel[0].goToLastSlide()
                return
              }
          }
        }
      }
    },

    flag() {
      this.create = true
    },

    //画像の削除
    deleteImage(formID,estateID) {
      this.$swal({
        title: '写真削除',
        html: `写真を削除しますか？`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText:'キャンセル'
      }).then(result => {
        if(result.isConfirmed) {
          for(let photoForm of this.photoForms) {
            if(Object.keys(photoForm)[0] == estateID) {
              for(let t = 0;t < photoForm[estateID].length;t++) {
                for(let j = 0;j < photoForm[estateID][t].length;j++) {
                  if(photoForm[estateID][t][j].formID == formID) {
                    photoForm[estateID][t][j].shootDate = '',
                    photoForm[estateID][t][j].nextShoot = '',
                    photoForm[estateID][t][j].image = '',
                    photoForm[estateID][t][j].uploadFile =[]
                    photoForm[estateID][t][j].resizeUploadFile=[]
                    photoForm[estateID][t][j].uploadFlag = false
                    photoForm[estateID][t][j].message.notDate = ''
                    photoForm[estateID][t][j].existingImage = ''
                    photoForm[estateID][t][j].photoID = ''
                    photoForm[estateID][t][j].othersName = ''
                    photoForm[estateID][t][j].processNumber = ''
                  }
                }
              }
            }
          }
        }
      })
    },
    //写真登録モーダル
    postPropertyPhotoModal(locationID,estateID,estateName,estateData) {
      let errorMessage = []
      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let t = 0;t <  photoForm[estateID].length;t++) {
            for(let p = 0;p < photoForm[estateID][t].length;p++) {
              if(photoForm[estateID][t][p].photoFormSortflag) {
                errorMessage.push('並べ替え処理中の写真があります。')
              }
            }
          }
        }
      }
      for(let locationData of this.locationDatas) {
        if(locationData.cEstateID == estateID && locationData.photoRegisteredFlag) {
          errorMessage.push('既に登録処理済み物件です。')
        }
      }
      if(errorMessage.length != 0) {
        for(let error of errorMessage) {
          this.$swal({
            icon: 'error',
            html:`${error}<br>`,
            confirmButtonText:"閉じる"
          })
        }

        return
      } else {
        let registPhotoData = []
        for(let insidePlace of this.insidePlaces) {
          registPhotoData.push({[insidePlace.id]:[]})
        }

        this.$swal({
          title: '写真登録',
          html: `「${estateName}」の写真を登録しますか？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '確定',
          cancelButtonText:'キャンセル'
        })
        .then((result) => {
          if(result.isConfirmed) {
            this.$swal({
              title: '処理中',
              html: `「${estateName}」の写真を登録中です。`,
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
            });
            for(let locationData of this.locationDatas) {
              if(locationData.cEstateID == estateID) {
                locationData.photoRegisteredFlag = true
              }
            }
            let date = moment().format("YYYYMMDDHHmmss")
            for(let photoForm of this.photoForms) {
              if(Object.keys(photoForm) == estateID) {
                for(let i = 0;i < photoForm[estateID].length;i++) {
                  for(let t = 0;t < photoForm[estateID][i].length;t++) {
                    if(photoForm[estateID][i][t].uploadFile.length != 0 ||
                      photoForm[estateID][i][t].existingImage !== '' && photoForm[estateID][i][t].photoID)
                    {
                      for(let registPhoto of registPhotoData) {
                        if(Object.keys(registPhoto) == photoForm[estateID][i][t].interiorID) {
                          registPhoto[photoForm[estateID][i][t].interiorID].push(photoForm[estateID][i][t])
                        }
                      }
                    }
                  }
                }
              }
            }

            let sendPhotoData = []
              for(let insidePlace of this.insidePlaces) {
                for(let i = 0;i < registPhotoData.length;i++) {
                  if(typeof registPhotoData[i][insidePlace.id] == "undefined") {
                    continue
                  } else {
                    if(registPhotoData[i][insidePlace.id].length !== 0) {
                      sendPhotoData.push(registPhotoData[i][insidePlace.id])
                    }
                  }
                }
              }
              for(let sendPhoto of sendPhotoData) {
                for(let photo of sendPhoto) {
                  if(photo.photoID == '') {
                    let extensionType = ''
                    photo['imgType'] = photo.uploadFile.type
                    if(photo.uploadFile.type == 'image/jpeg') {
                      extensionType = '.jpeg'
                    } else if(photo.uploadFile.type == 'image/jpg') {
                      extensionType = '.jpg'
                    }
                    photo['imgName'] = [
                      this.shootingType,
                      locationID,
                      estateID,
                      estateName,
                      photo.interiorID,
                      date,
                      sendPhoto.indexOf(photo) + 1].join('_') + extensionType
                  } else {
                    photo['imgName'] = photo.existingImage.replace('/resizephoto/','')
                  }
                  photo.orderNo = sendPhoto.indexOf(photo) + 1
                  this.checkProcessNumber(photo)
                }
              }
            this.postEstatePhoto(
              sendPhotoData,
              estateID,
              this.userID,
              this.shootingID,
              estateName,
              estateData,
              this.s3,
              this.s3Bucket
            )
          }
        })
      }
    },
    //画像をドラッグオーバー時
    onDrag(type,formID,estateID) {
      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let t = 0; t < photoForm[estateID].length;t++) {
            for(let p = 0;p < photoForm[estateID][t].length;p++) {
              if(photoForm[estateID][t][p].formID === formID ) {
                photoForm[estateID][t][p].isDragOver = type === "over";
              }
            }
          }
        }
      }
    },
    //写真投稿数の確認
    checkForm(estateID,formID) {
      let insidePlace = ''
      insidePlace = formID.replace(/[0-9]/g, '')

      let result = true
      for(let photoForm of this.photoForms) {
        if(Object.keys(photoForm)[0] == estateID) {
          for(let t = 0;t < photoForm[estateID].length;t++) {
            for(let p = 0;p < photoForm[estateID][t].length;p++) {
              if(photoForm[estateID][t][p].insidePlace == insidePlace) {
                if(!photoForm[estateID][t][p].uploadFlag) {
                  result =false
                  break
                }
              }
            }
          }
        }
      }
      if(result) {
        insidePlace = (estateID + insidePlace)
        for(let i = 0;i < this.estateDatas.length;i++) {
          if(Object.keys(this.estateDatas[i])[0] == estateID) {
            for(let j = 0;j < this.estateDatas[i][estateID].inside[estateID].length;j++) {
              if(Object.keys(this.estateDatas[i])[0] == estateID &&
                Object.keys(this.estateDatas[i][estateID].inside[estateID][j])[0] == insidePlace) {
                this.estateDatas[i][estateID].inside[estateID][j][insidePlace].requiredFormFlag = true
                return
              }
            }
          }
        }
      }
    },
    //工程番号確認(マンションか戸建てかチェック)
    checkProcessNumber(photoForm) {
      if(this.locationType == 2) {
        for(let houseProcessNumber of this.houseProcessNumbers) {
          if(houseProcessNumber.id == photoForm.interiorID && houseProcessNumber.orderNo == photoForm.orderNo) {
            photoForm.processNumber = houseProcessNumber.processNumber
          }
        }
      } else if(this.locationType == 3) {
        for(let mansionProcessNumber of this.mansionProcessNumbers) {
          if(mansionProcessNumber.id == photoForm.interiorID && mansionProcessNumber.orderNo == photoForm.orderNo) {
            photoForm.processNumber = mansionProcessNumber.processNumber
          }
        }
      }
    },

    //該当のタブへ遷移（複数物件の場合）
    getTabIndex() {
      let timer = setInterval(() => {
        if(this.tabIndex == 0){
          clearInterval(timer);
          for(let locationData of this.locationDatas) {
            if(locationData.cEstateID == this.estateID) {
              this.tabIndex = Number(locationData['tabIndex'])
              return
            }
          }
        }
      },100)
    },


  },

  watch:{
    'introspectionDatas':function() {
      this.createintrospectionDatas()
    },
    'locationDatas':function() {
      for(let locationData of this.locationDatas) {
        this.makeEstateData(
          locationData.cEstateID,
          locationData.vcEstateName,
          this.introspectionDatas,
          locationData.vcMatterportURL,
          locationData.dtDeadlineMatterport
        )
        this.makePhotoForms(
          locationData.cEstateID,
          locationData.vcEstateName,
          locationData.photo
        )
      }
      this.locationType = this.locationDatas[0].tiTypeCd
    },
  }

}

</script>

<style scoped type="text/css">

.custom-file-drop .custom-file-drop-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
}
.custom-file-drop {
    position: relative;
}
.custom-file-drop .custom-file-drop-label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding: 0.375rem 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.95);
    color: #909ead;
    cursor: pointer;
}

.formLabel {
  display: inline-block;
  width: 140px;
  text-align: right;
}

.image-input {
  background-color: #eee;
  width: 100%;
  height: 300px;

}
.image-input__field {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-input__field.over {
  background-color: #666;
}
.image-input__field > input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.image-input__field > p {
  color: #aaa;
  text-align: center;
}

.add_btn:hover {
  opacity: 0.5;
}

  .ddSort {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .ddSort:hover {
    cursor: grab;
  }
  .ddSort:active {
    cursor: grabbing;
  }

.property_info {
  position: sticky;
  top:95px;
  background-color: white;
  z-index:100;
}


.scafold-wrapper {
  flex-direction: column;
  min-height: 100vh;
  width: calc(100% - 200px);
  margin: 0 0 0 180px;
}

.sidebar-area {
  height: 100vh;
  width:auto;
  position: sticky;
  top:95px;
}
@media screen and (max-width: 750px) {
.sidebar-area{
    display:none;
  }
}
</style>
