import EXIF from "exif-js"
import piexif from "piexifjs"
import moment from "moment";
export const UploadImage = {
  data() {
    return {
      message: {
        file:'',
        notExif:'',
        notDate:''
      },
      isDragOver: false,
      drag:false,

    }
  },
  methods:{
    //ドラックアンドドロップで写真投稿時
    onDrop(event) {
      const files = event.dataTransfer.files;
      const formIndex = Number(event.target.name.substring(event.target.name.indexOf(',')+1)) + Number(1)
      const formID = event.target.name;
      const estateID = event.target.id;
      const formType = event.target.title;

      switch(formType) {
        //外観戸建ての場合
        case 'exterior':
          for(let i = 0;i <this.photoForms.length;i++) {
            if(Object.keys(this.photoForms[i])[0] == estateID) {
              for(let t = 0;t < this.photoForms[i][estateID].length;t++) {
                this.photoForms[i][estateID][t].isDragOver = false;
              }
            }
          }
        break;
        //外観戸建て造成中の場合
        case 'construction':
          for(let i = 0;i <this.constructionPhotoForms.length;i++) {
            if(Object.keys(this.constructionPhotoForms[i])[0] == estateID) {
              for(let t = 0;t < this.constructionPhotoForms[i][estateID].length;t++) {
                this.constructionPhotoForms[i][estateID][t].isDragOver = false;
              }
            }
          }
        break;
        //マンションの場合
        case 'mansion':
          for(let i = 0;i < this.mansionPhotoForms.length;i++) {
            if(Object.keys(this.mansionPhotoForms[i])[0] == estateID) {
              for(let t = 0;t < this.mansionPhotoForms[i][estateID].length;t++) {
                for(let photoForm of this.mansionPhotoForms[i][estateID][t]) {
                  photoForm.isDragOver = false;
                }
              }
            }
          }
        break;
        //内観の場合
        default:
          for(let i = 0;i <this.photoForms.length;i++) {
            if(Object.keys(this.photoForms[i])[0] == estateID) {
              for(let t = 0;t < this.photoForms[i][estateID].length;t++) {
                for(let p = 0;p < this.photoForms[i][estateID][t].length;p++) {
                  this.photoForms[i][estateID][t][p].isDragOver = false;
                }
              }
            }
          }
      }
      this.drag = true;
      this.message.file = ''
      this.notDate = ''

      if(files[0].type !== 'image/jpeg' ) {
        this.$swal({
          icon: 'error',
          html:'写真はjpegファイルでアップロードしてください',
          title:'写真登録エラー',
          confirmButtonText:"閉じる"
        })
        return
      }
      if (files[0].type.indexOf("image") !== 0) {
        this.$swal({
          icon: 'error',
          html:'画像ファイルをアップロードしてください',
          title:'写真登録エラー',
          confirmButtonText:"閉じる"
        })
        return
      }
      this.getExif(
        files[0],
        formID,
        estateID,
        formIndex,
        formType
      );
    },
    //クリックして写真投稿時
    onChange(event) {
      this.message.file = ''
      this.notDate = ''
      const formIndex = Number(event.target.name.substring(event.target.name.indexOf(',')+1)) + Number(1)
      const files = event.target.files;
      const formID = event.target.name.split(',')[0];
      const estateID = event.target.id;
      const formType = event.target.title;

      if(files[0].type != 'image/jpeg' ) {
        this.$swal({
          icon: 'error',
          html:'写真はjpegファイルでアップロードしてください',
          title:'写真登録エラー',
          confirmButtonText:"閉じる"
        })
        return
      }
      if (files[0].type.indexOf("image") !== 0) {
        this.$swal({
          icon: 'error',
          html:'画像ファイルをアップロードしてください',
          title:'写真登録エラー',
          confirmButtonText:"閉じる"
        })
        return
      }
      this.getExif(
        files[0],
        formID,
        estateID,
        formIndex,
        formType
      );
    },
     //内観写真データの読み込み、プレビュー表示
    imageUpload(files,formID,estateID,formIndex) {
      let self = this
      let reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function(e) {
        let img = new Image();
        for(let i = 0; i < self.photoForms.length;i++) {
          if(Number(Object.keys(self.photoForms[i])[0]) == estateID) {
            for(let t = 0; t < self.photoForms[i][estateID].length;t++) {
              for(let p = 0;p < self.photoForms[i][estateID][t].length;p++) {
                if(self.photoForms[i][estateID][t][p].formID === formID) {
                    self.photoForms[i][estateID][t][p].uploadFile = files;
                  img.src = e.target.result;
                  self.photoForms[i][estateID][t][p].image = img;
                  self.photoForms[i][estateID][t][p].uploadFlag = true
                  self.photoForms[i][estateID][t][p].orderNo = formIndex
                  self.checkForm(
                    estateID,
                    formID
                  )
                }
              }
            }
          }
        }
      }
    },

    //外観写真の写真アップロード、プレビュー表示
    exteriorImageUpload(files,formID,estateID,formIndex,formType,needExif) {
      let self = this
      let reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function(e) {
        let img = new Image();
        switch(formType){
          //外観戸建ての場合
          case 'exterior':
            for(let i = 0; i < self.photoForms.length;i++) {
              if(Object.keys(self.photoForms[i])[0] == estateID) {
                for(let t = 0; t < self.photoForms[i][estateID].length;t++) {
                  if(self.photoForms[i][estateID][t].formID == formID) {
                    self.photoForms[i][estateID][t].uploadFile = files;
                    if(needExif){
                      let exifIfd = {};
                      exifIfd[piexif.ExifIFD.DateTimeOriginal] = moment(Date.now()).format('YYYY:MM:DD HH:mm:ss');
                      exifIfd[piexif.ExifIFD.LensMake] = "Lens Maker";
                      let exifObj = {"Exif":exifIfd};
                      let exifBytes = piexif.dump(exifObj);
                      let inserted = piexif.insert(exifBytes, e.target.result);
                      img.src = inserted;
                      img.onload = function() {
                        // キャンバスを作成
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');

                        // 画像をキャンバスに描画
                        canvas.width = img.width;
                        canvas.height = img.height;
                        context.drawImage(img, 0, 0, img.width, img.height);

                        // キャンバスからバイナリデータを取得
                        canvas.toBlob(function(blob) {
                          // blobオブジェクトをバイナリデータとして使用できます
                          self.photoForms[i][estateID][t].uploadFile = blob;

                          // ここでバイナリデータを使用できます
                        }, 'image/jpeg'); // 画像フォーマットを指定
                      };
                    }else{
                      img.src = e.target.result;
                    }
                    self.photoForms[i][estateID][t].image = img;
                    self.photoForms[i][estateID][t].uploadFlag = true
                    if(self.photoForms[i][estateID][t].formID == self.photoForms[i][estateID][2].formID) {
                      self.copyPhotoFormChecks.push({
                        estateID:self.photoForms[i][estateID][0].estateID,
                        estateName:self.photoForms[i][estateID][0].estateName
                      })
                    }

                    self.checkForm(
                      estateID,
                      formType
                    )
                  }
                }
              }
            }
          break;
          //外観戸建て造成中の場合
          case 'construction':
            for(let constructionPhotoForm of self.constructionPhotoForms) {
              if(Object.keys(constructionPhotoForm)[0] == estateID) {
                constructionPhotoForm[estateID][0].uploadFile = files;
                if(needExif){
                  let exifIfd = {};
                  exifIfd[piexif.ExifIFD.DateTimeOriginal] = moment(Date.now()).format('YYYY:MM:DD HH:mm:ss');
                  exifIfd[piexif.ExifIFD.LensMake] = "Lens Maker";
                  let exifObj = {"Exif":exifIfd};
                  let exifBytes = piexif.dump(exifObj);
                  let inserted = piexif.insert(exifBytes, e.target.result);
                  img.src = inserted;
                  img.onload = function() {
                    // キャンバスを作成
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');

                    // 画像をキャンバスに描画
                    canvas.width = img.width;
                    canvas.height = img.height;
                    context.drawImage(img, 0, 0, img.width, img.height);

                    // キャンバスからバイナリデータを取得
                    canvas.toBlob(function(blob) {
                      // blobオブジェクトをバイナリデータとして使用できます
                      constructionPhotoForm[estateID][0].uploadFile = blob;
                      // ここでバイナリデータを使用できます
                    }, 'image/jpeg'); // 画像フォーマットを指定
                  };
                }else{
                  img.src = e.target.result;
                }
                constructionPhotoForm[estateID][0].image = img;
                constructionPhotoForm[estateID][0].uploadFlag = true
                self.copyConstructionChecks.push({
                  estateID:constructionPhotoForm[estateID][0].estateID,
                  estateName:constructionPhotoForm[estateID][0].estateName
                })
                self.checkForm(
                  estateID,
                  formType
                )
              }
            }
          break;
          //マンションの場合
          case 'mansion':
            for(let i = 0; i < self.mansionPhotoForms.length;i++) {
              if(Object.keys(self.mansionPhotoForms[i])[0] == estateID) {
                for(let t = 0; t < self.mansionPhotoForms[i][estateID].length;t++) {
                  for(let mansionPhotoForm of self.mansionPhotoForms[i][estateID][t]) {
                    if(mansionPhotoForm.formID == formID) {
                      mansionPhotoForm.uploadFile = files;
                      if(needExif){
                        let exifIfd = {};
                        exifIfd[piexif.ExifIFD.DateTimeOriginal] = moment(Date.now()).format('YYYY:MM:DD HH:mm:ss');
                        exifIfd[piexif.ExifIFD.LensMake] = "Lens Maker";
                        let exifObj = {"Exif":exifIfd};
                        let exifBytes = piexif.dump(exifObj);
                        let inserted = piexif.insert(exifBytes, e.target.result);
                        img.src = inserted;
                        img.onload = function() {
                          // キャンバスを作成
                          const canvas = document.createElement('canvas');
                          const context = canvas.getContext('2d');

                          // 画像をキャンバスに描画
                          canvas.width = img.width;
                          canvas.height = img.height;
                          context.drawImage(img, 0, 0, img.width, img.height);

                          // キャンバスからバイナリデータを取得
                          canvas.toBlob(function(blob) {
                            // blobオブジェクトをバイナリデータとして使用できます
                            mansionPhotoForm.uploadFile = blob;
                            // ここでバイナリデータを使用できます
                          }, 'image/jpeg'); // 画像フォーマットを指定
                        };
                      }else{
                        img.src = e.target.result;
                      }
                      mansionPhotoForm.image = img;
                      mansionPhotoForm.uploadFlag = true
                      self.checkForm(
                        estateID,
                        formType,
                        mansionPhotoForm.shotPlace
                      )
                    }
                  }

                }
              }
            }
          break;
        }
      }
    },

    //写真よりexif情報取得処理
    getExif(files,formID,estateID,formIndex,formType) {
      let self = this;
      let dateTime = '';
      let nextShootDate = '';
      let needExif = false
      EXIF.getData(files, function() {
        dateTime = EXIF.getTag(this,"DateTimeOriginal")
        if(dateTime == undefined){
          needExif = true
          dateTime = Date.now()
        }else{
          dateTime = dateTime.replace(/(\d+):(\d+):(\d+) (\d+):(\d+):(\d+)/g, "$1-$2-$3 $4:$5:$6");
        }
        dateTime = moment(dateTime).format('YYYY-MM-DD HH:mm:ss')
        nextShootDate = moment().add(7,'days').format('YYYY-MM-DD')
        switch(formType) {
            //外観戸建ての場合
          case 'exterior':
            for(let i = 0; i < self.photoForms.length;i++) {
              if(Object.keys(self.photoForms[i])[0] == estateID) {
                for(let t = 0;t < self.photoForms[i][estateID].length;t++) {
                  if(self.photoForms[i][estateID][t].formID == formID) {
                    self.photoForms[i][estateID][t].shootDate = dateTime
                    self.photoForms[i][estateID][t].nextShoot = nextShootDate
                  }
                }
              }
            }
            self.exteriorImageUpload(
                files,
                formID,
                estateID,
                formIndex,
                formType,
                needExif
            );
            break;
            //外観戸建て造成中の場合
          case 'construction':
            for(let i = 0; i < self.constructionPhotoForms.length;i++) {
              if(Object.keys(self.constructionPhotoForms[i])[0] == estateID) {
                for(let t = 0;t < self.constructionPhotoForms[i][estateID].length;t++) {
                  if(self.constructionPhotoForms[i][estateID][t].formID == formID) {
                    self.constructionPhotoForms[i][estateID][t].shootDate = dateTime
                    self.constructionPhotoForms[i][estateID][t].nextShoot = nextShootDate
                  }
                }
              }
            }
            self.exteriorImageUpload(
                files,
                formID,
                estateID,
                formIndex,
                formType,
                needExif
            );
            break;
            //マンションの場合
          case 'mansion':
            for(let i = 0; i < self.mansionPhotoForms.length;i++) {
              if(Object.keys(self.mansionPhotoForms[i])[0] == estateID) {
                for(let t = 0;t < self.mansionPhotoForms[i][estateID].length;t++) {
                  for(let mansionPhotoForm of self.mansionPhotoForms[i][estateID][t]) {
                    if(mansionPhotoForm.formID == formID) {
                      mansionPhotoForm.shootDate = dateTime
                      mansionPhotoForm.nextShoot = nextShootDate
                    }
                  }

                }
              }
            }
            self.exteriorImageUpload(
                files,
                formID,
                estateID,
                formIndex,
                formType,
                needExif
            );
            break;
            //内観の場合
          default:
            for(let i = 0; i < self.photoForms.length;i++) {
              if(Object.keys(self.photoForms[i])[0] == estateID) {
                for(let t = 0;t < self.photoForms[i][estateID].length;t++) {
                  for(let p = 0;p < self.photoForms[i][estateID][t].length;p++) {
                    if(self.photoForms[i][estateID][t][p].formID == formID) {
                      self.photoForms[i][estateID][t][p].shootDate = dateTime
                    }
                  }
                }
              }
            }
            self.imageUpload(
                files,
                formID,
                estateID,
                formIndex
            );
        }
      })
    },
  }
}
