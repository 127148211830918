<template>
  <div >
  <h6>{{ locationName }}</h6>
    <b-list-group  
      class="sidebar-link-area "
      v-for="(category,categoryIndex) in categories"
      :key="categoryIndex"
    >
      <b-list-group-item  
        class="sidebar-link"  
        href="#" 
        @click="scrollProperty('#' + 'a' + estateID + category.value)"
      >
      {{category.value}}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>

export default {
  props:{
    estateID:[],
    locationName:String
  },
  data() {
    return {
      selectInsidePlace:'',
      categories:[
        {categoryID:1,value:'リビング・キッチン'},
        {categoryID:2,value:'居室'},
        {categoryID:3,value:'水回り'},
        {categoryID:4,value:'玄関'},
        {categoryID:5,value:'収納'},
        {categoryID:6,value:'外回り'},
        {categoryID:7,value:'その他'},
      ],
      insidePlaces:[
          {text:'リビング',value:'リビング',category:'リビング・キッチン'},
          {text:'キッチン',value:'キッチン',category:'リビング・キッチン'},
          {text:'居室(和室・洋室)',value:'居室(和室・洋室)',category:'居室'},
          {text:'居室(子供部屋)',value:'居室(子供部屋)',category:'居室'},
          {text:'居室(寝室)',value:'居室(寝室)',category:'居室'},
          {text:'トイレ(リビング)',alue:'トイレ(リビング)',category:'水回り'},        
          {text:'トイレ(その他)',value:'トイレ(その他)',category:'水回り'},
          {text:'浴室',value:'浴室',category:'水回り'},
          {text:'洗面台・洗面所',value:'洗面台・洗面所',category:'水回り'},
          {text:'玄関(玄関から室内)',value:'玄関(玄関から室内)',category:'玄関'},
          {text:'玄関(室内から玄関)',value:'玄関(室内から玄関)',category:'玄関'},
          {text:'バルコニー',value:'バルコニー',category:'外回り'},
          {text:'駐車場',value:'駐車場',category:'外回り'},
          {text:'外観(シャッター無し)',value:'外観(シャッター無し)',category:'外回り'},
          {text:'眺望',value:'眺望',category:'外回り'},
          {text:'収納(ウォークインクローゼット)',value:'収納(ウォークインクローゼット)',category:'収納'},
          {text:'収納(シューズクローク)',alue:'収納(シューズクローク)',category:'収納'},        
          {text:'収納(ロフト)',value:'収納(ロフト)',category:'収納'},
          {text:'収納(床下)',value:'収納(床下)',category:'収納'},
          {text:'パノラマ',value:'パノラマ',category:'その他'},
          {text:'その他',value:'その他',category:'その他'},
        ],

    }
  },
  methods: {
    //ページスクロール
    scrollProperty(category) {
      this.$scrollTo(category,{
        offset:-280
      })
    }
  }
}
</script>

<style>

.sidebar-link-area {
  padding-top: 10px; 
}


</style>