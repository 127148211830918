var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('b-container',{staticStyle:{"padding-top":"70px","max-width":"95%","font-size":"14px"}},[_c('b-overlay',{attrs:{"show":_vm.showLoader,"rounded":"sm"}},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-0"},[_vm._v(" 内観写真登録 ")])])],1),_c('b-tabs',{attrs:{"nav-wrapper-class":"tab-info","lazy":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.locationDatas),function(locationData,locationIndex){return _c('b-tab',{key:locationIndex,attrs:{"title":locationData.vcEstateName},on:{"click":_vm.pageTop}},[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('SideMenu',{staticClass:"sidebar-area",attrs:{"estateID":locationData.cEstateID,"locationName":locationData.vcLocationName}})],1),_c('b-col',{attrs:{"md":"10"}},[_c('b-row',{staticClass:"property_info"},[_c('b-col',{attrs:{"md":"3"}},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(locationData.vcEstateName)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(locationData.vcAddress)+" ")]),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" 備考: "+_vm._s(locationData.txInterior)+" ")])]),_c('b-col',{staticClass:"ms-2"},[_c('b-row',{staticStyle:{"overflow-x":"scroll","max-height":"150px"}},_vm._l((locationData.material),function(materialData,materialDataIndex){return _c('b-col',{key:materialDataIndex},[(materialData.vcStorage)?_c('b-col',[_c('b-img',{class:{'img_link' : materialData.vcStorage != _vm.noImage},staticStyle:{"max-width":"200px","max-height":"130px"},attrs:{"src":materialData.vcStorage},on:{"click":function($event){return _vm.showEstateImage(
                              materialData.vcStorage,
                              locationData.vcLocationName,
                              locationData.vcEstateName,

                            )}}})],1):(!materialData.vcMaterialID.includes('zip'))?_c('b-col',[_c('b-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{name:'PDFPage', params : {
                            materialID: materialData.vcMaterialID,
                            materialName: materialData.materialName }},"target":"_blank"}},[_vm._v(" "+_vm._s(materialData.materialName)+" "),_c('pdf',{staticStyle:{"max-width":"200px","height":"100px"},attrs:{"src":materialData.materialURL}})],1)],1):(materialData.vcMaterialID.includes('zip'))?_c('b-col',[_c('b-link',{attrs:{"href":materialData.materialURL,"download":""}},[_vm._v(" "+_vm._s(materialData.materialName)+"(zip) ")])],1):_vm._e()],1)}),1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[(locationData.photo.length == 0)?_c('b-button',{staticStyle:{"width":"50%"},attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.postPropertyPhotoModal(
                        locationData.cLocationID,
                        locationData.cEstateID,
                        locationData.vcEstateName,
                        _vm.estateDatas[locationIndex][locationData.cEstateID],
                      )}}},[_vm._v(" 写真を登録する ")]):_c('b-button',{staticStyle:{"width":"50%"},attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.postPropertyPhotoModal(
                        locationData.cLocationID,
                        locationData.cEstateID,
                        locationData.vcEstateName,
                        _vm.estateDatas[locationIndex][locationData.cEstateID]
                      )}}},[_vm._v(" 写真を更新する ")])],1)],1),_c('hr',{staticClass:"mb-1"})],1),_c('b-row',[_c('label',{staticClass:"col-lg-2 col-md-3 col-form-label w-auto",attrs:{"for":"matterportURL"}},[_vm._v(" マターポートURL: ")]),_c('b-col',{attrs:{"md":"4","lg":"auto","sm":"5"}},[_c('b-form-input',{attrs:{"trim":"","size":"sm","id":"matterportURL","type":"text"},model:{value:(_vm.estateDatas[locationIndex][locationData.cEstateID].matterportURL),callback:function ($$v) {_vm.$set(_vm.estateDatas[locationIndex][locationData.cEstateID], "matterportURL", $$v)},expression:"estateDatas[locationIndex][locationData.cEstateID].matterportURL"}})],1),_c('label',{staticClass:"col-lg-2 col-md-3 col-form-label w-auto",attrs:{"for":"deadlineMatterport"}},[_vm._v(" URL有効期限: ")]),_c('b-col',{attrs:{"md":"4","lg":"auto","sm":"5"}},[_c('b-form-input',{attrs:{"size":"sm","id":"deadlineMatterport","type":"date"},model:{value:(_vm.estateDatas[locationIndex][locationData.cEstateID].deadlineMatterport),callback:function ($$v) {_vm.$set(_vm.estateDatas[locationIndex][locationData.cEstateID], "deadlineMatterport", $$v)},expression:"estateDatas[locationIndex][locationData.cEstateID].deadlineMatterport"}})],1)],1),_vm._l((_vm.photoForms[locationIndex][locationData.cEstateID]),function(insidePhotoForm,index){return _c('b-row',{key:index,staticClass:"mb-3"},[(!insidePhotoForm[0].photoFormSortflag)?_c('b-col',{attrs:{"md":"11"}},[_c('b-overlay',{attrs:{"variant":"secondary","opacity":"0.4","show":!insidePhotoForm[0].showForm},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div')]},proxy:true}],null,true)},[_c('div',{attrs:{"id":'a' + insidePhotoForm[0].category}},[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.sortPhoto(
                          insidePhotoForm[0].estateID,
                          insidePhotoForm[0].insidePlace,index
                        )}}},[_vm._v(" 写真並び替え ")])],1),_c('carousel',{ref:"carousel",refInFor:true,attrs:{"per-page":_vm.mobile ? 1 : 3,"navigationEnabled":_vm.mobile ? false: true,"mouseDrag":false,"touchDrag":true,"value":_vm.carouselPage,"navigation-prev-label":"〈","navigation-next-label":"〉"}},_vm._l((insidePhotoForm),function(photoForm,formIndex){return _c('slide',{key:photoForm.insidePlace + formIndex,staticClass:"p-2"},[_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(photoForm.insidePlace + (formIndex + 1))+" ")]),(photoForm.insidePlace == 'その他')?_c('b-col',{attrs:{"md":"9"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"col-form-label",attrs:{"for":photoForm.insidePlace + formIndex}},[_vm._v(" 名称: ")])]),_c('b-col',[_c('b-form-input',{staticClass:"col col-md-3",attrs:{"id":photoForm.insidePlace + formIndex,"type":"text"},model:{value:(photoForm.othersName),callback:function ($$v) {_vm.$set(photoForm, "othersName", $$v)},expression:"photoForm.othersName"}})],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"image-input mx-auto"},[_c('div',{staticClass:"image-input__field",class:{'over': photoForm.isDragOver},attrs:{"name":[photoForm.formID,formIndex],"id":photoForm.estateID},on:{"dragover":function($event){$event.preventDefault();return _vm.onDrag('over',photoForm.formID,photoForm.estateID)},"dragleave":function($event){return _vm.onDrag('leave',photoForm.formID,photoForm.estateID)},"drop":function($event){$event.stopPropagation();return _vm.onDrop.apply(null, arguments)}}},[(!photoForm.image.src && photoForm.existingImage == '')?_c('input',{attrs:{"name":[photoForm.formID,formIndex],"id":photoForm.estateID,"type":"file"},on:{"change":_vm.onChange}}):_vm._e(),(photoForm.image.src && photoForm.existingImage == '')?_c('div',[_c('div',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.deleteImage(
                                    photoForm.formID,
                                    photoForm.estateID
                                  )}}})]),_c('b-img',{staticClass:"img-thumbnail d-flex mx-auto",staticStyle:{"max-height":"280px"},attrs:{"src":photoForm.image.src}})],1):(!photoForm.image.src && photoForm.existingImage != '')?_c('div',[_c('div',{staticStyle:{"text-align":"right"}},[_c('button',{staticClass:"btn-close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.deleteImage(
                                    photoForm.formID,
                                    photoForm.estateID
                                    )}}})]),_c('b-img',{staticClass:"img-thumbnail d-flex mx-auto",staticStyle:{"max-height":"280px"},attrs:{"src":photoForm.existingImage}})],1):_c('p',[_vm._v(" 画像をドラッグ＆ドロップ "),_c('br'),_vm._v("またはクリックでファイル選択 ")])])])],1)}),1)],1)],1):_vm._e(),(_vm.create && !insidePhotoForm[0].photoFormSortflag)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"md":"1"}},_vm._l((_vm.estateDatas[locationIndex][locationData.cEstateID].inside[locationData.cEstateID]),function(insideData,insideDataIndex){return _c('span',{key:insideDataIndex},[(insideData[locationData.cEstateID + insidePhotoForm[0].insidePlace])?_c('span',[_c('b-img',{staticClass:"img_link add_btn",attrs:{"hidden":!insideData[locationData.cEstateID + insidePhotoForm[0].insidePlace].requiredFormFlag,"src":"/static/images/addbtn.png"},on:{"click":function($event){return _vm.makeAddInsidePhotoForms(
                          insidePhotoForm[0].estateID,
                          insidePhotoForm[0].insidePlace,
                          insidePhotoForm[0].interiorID,
                          insidePhotoForm[0].estateName
                        )}}})],1):_vm._e()])}),0):_vm._e(),(insidePhotoForm[0].photoFormSortflag)?_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.changeSort(insidePhotoForm[0].estateID,insidePhotoForm[0].insidePlace)}}},[_vm._v(" 並び替える ")])],1):_vm._e(),(insidePhotoForm[0].photoFormSortflag)?_c('b-row',{staticStyle:{"overflow-x":"scroll"}},[_c('draggable',{staticClass:"d-flex flex-row ddSort",attrs:{"animation":200},model:{value:(_vm.photoForms[locationIndex][locationData.cEstateID][_vm.count]),callback:function ($$v) {_vm.$set(_vm.photoForms[locationIndex][locationData.cEstateID], _vm.count, $$v)},expression:"photoForms[locationIndex][locationData.cEstateID][count]"}},_vm._l((insidePhotoForm),function(photoForm,formIndex){return _c('b-col',{key:photoForm.insidePlace + formIndex,staticStyle:{"margin-right":"15px"},attrs:{"md":"4"}},[_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(photoForm.insidePlace + (formIndex + 1))+" ")])],1),_c('div',{staticClass:"image-input mx-auto col-md-6"},[_c('div',{staticClass:"image-input__field"},[(photoForm.image.src && photoForm.existingImage == '')?_c('div',[_c('b-img',{staticClass:"img-thumbnail d-flex mx-auto",staticStyle:{"max-height":"280px"},attrs:{"src":photoForm.image.src}})],1):(!photoForm.image.src && photoForm.existingImage != '')?_c('div',[_c('b-img',{staticClass:"img-thumbnail d-flex mx-auto",staticStyle:{"max-height":"280px"},attrs:{"src":photoForm.existingImage}})],1):_c('p',[_vm._v("画像をドラッグ＆ドロップ "),_c('br'),_vm._v("またはクリックでファイル選択 ")])])])],1)}),1)],1):_vm._e()],1)}),_c('hr')],2)],1)],1)}),1)],1)],1),_c('ShowPDFModal',{attrs:{"pdfData":_vm.pdfData}}),_c('ShowEstatePhotoModal',{attrs:{"estateName":_vm.photoEstateName,"locationName":_vm.photoLocationName,"estateImage":_vm.photoEstateImage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }